
<template>
  <div class='chartWrap'>
    <div class="multi_modal_img" style="position: relative;" v-if="isCogvlmModel">
      <img v-if="imageUrl" class="temp_img" :src="imageUrl">
      <el-upload action="" drag :auto-upload="true" :show-file-list="false" :before-upload="handleBeforeUpload"
        :multiple="false" :limit="1" accept="image/x-png,image/jpeg" :style="{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          opacity: imageUrl ? 0 : 1
        }">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div class="line" v-if="isCogvlmModel"></div>
    <div class="chat_content" ref="srcollRef" :class="{'isCogvlmModel':isCogvlmModel}">
      <div class="chatRecords">
        <div v-for="(item,index) in chatRecords" :key="index">
          <div class="person_msg" v-if="item.type == 1">
            <div class="word">{{ item.word }}</div>
          </div>
          <div class="ai_msg" v-if="item.type == 2">
            <div class="avatar_wrap">
              <img src="@/assets/images/robo1.png" alt="" />
              <span>{{info.name}}</span>
            </div>
            <div class="word" v-if="item.word">
              <!-- <span id="response_row" class="result-streaming" v-if="item.requestFlag"></span> -->
              <!-- <span style="white-space:pre-wrap" v-html="item.word"></span> -->
              <my-md-preview :text="item.word" theme="light" show-code-row-number
                preview-theme="default"></my-md-preview>
            </div>

            <template v-if="item.img">
              <el-image :src="`data:image/jpeg;base64,${item.img}`"
                :preview-src-list="[`data:image/jpeg;base64,${item.img}`]">
              </el-image>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-show="loading"></div>
    <template v-if="!(isCogvlmModel&&!imageUrl)">
      <el-input v-model='question' @keyup.enter.native="sendMessage" :placeholder="placeholderText" class='chart-input'>
        <el-button @click='sendMessage' slot="suffix" icon='el-icon-top' class="chart-btn" :disabled="loading"
          v-show="!showStopBtn"></el-button>
        <el-button slot="suffix" v-show="showStopBtn" @click="stopAnswers" class="chart-btn"  icon='el-icon-video-pause' >
        </el-button>
      </el-input>

    </template>
  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
import pathMixin from './proxyPathMixin'
import { fetchEventSource } from '@microsoft/fetch-event-source';
export default {
  mixins: [pathMixin],
  props: {
    info: {
      type: Object,
      default: () => { }
    },
    id: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      imageUrl: '',
      historyChat: [],
      chatRecords: [
      ],
      loading: false,
      question: '',

      response: '',

      completeText: '',
      showStopBtn: false,
      abortController: null
    }
  },
  computed: {
    isCogvlmModel () {
      return this.info?.sceneTagNames?.includes('图像描述');
    },
    isStableDiffModel () {
      return this.info?.sceneTagNames?.includes('文本生成图片');
    },
    placeholderText () {
      if (this.isCogvlmModel) {
        return '请对图片问一个问题吧';
      } else if (this.isStableDiffModel) {
        return '请输入一段描述，即可根据描述生成一张图片哦';
      } else {
        return '您好，请输入';
      }
    }
  },
  watch: {
    response (newVal) {
      let record = { type: 2 };
      if (this.isStableDiffModel) {
        record.img = newVal;
      } else {
        record.word = newVal;
      }
      this.chatRecords.push(record);

      this.$nextTick(() => {
        const chatContent = this.$refs.srcollRef;
        if (chatContent) {
          chatContent.scrollTop = 9999999999;
        }
      });
    },
    id: {
      handler () {
        this.historyChat = [];
        this.imageUrl = '';
        this.chatRecords = []
      },
      immediate: true
    },
  },
  methods: {
    handleBeforeUpload (file) {
      this.historyChat = [];
      this.chatRecords = []
      file2base64(file).then(res => {
        this.imageUrl = res;
      }, err => {
        if (err === 'TYPE_INVALID') {
          this.$message.error('图片转base64失败');
        }
      });
      return false;
    },
    async sendMessage () {
      if (this.loading) return false;
      if (this.question.trim().length == 0) {
        return this.$message.warning("请输入问题");
      } else {
        this.chatRecords.push({
          type: 1,
          word: this.question,
        });
        let tempValue = this.question;
        this.question = "";
        this.$nextTick(() => {
          const chatContent = this.$refs.srcollRef;
          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
          }
        });
        this.loading = true;
        // 初始化请求数据的实例对象
        let instance = {
          text_chat: tempValue,
          history1: this.historyChat
        };
        if (this.isCogvlmModel) {
          instance.image_b64 = this.urlSateBase64Encode(this.imageUrl.match(/base64,(\S*)/)[1]);
        }
        if (!this.isStableDiffModel) {
          instance.stream = true; // 添加流式传输的标识
        }
        const requestData = {
          instances: [instance]
        };
        const url = this.info?.appDetailAddVo?.url;
        if (!url) {
          this.$message.error('URL不存在');
          return;
        }
        const apiTestUrl = this.extractProxyPath(url);
        if (!apiTestUrl) {
          this.$message.error('无法提取URL中的/proxy/部分');
          return;
        }
        const recordUrl = this.transformUrl(url);
        api.testRecord(recordUrl); // 日志记录

        if (this.isStableDiffModel) {
          try {
            const res = await api.modelApiTest(apiTestUrl, requestData)
            // this.handleResponse(res)
            this.response = res.result_img
          } catch (error) {
          } finally {
            this.loading = false;
            this.tempValue = ''
          }
        } else {
          this.streamHttp(apiTestUrl, requestData)
        }
      }
    },
    // handleResponse (res) {
    //   if (!this.isStableDiffModel) {
    //     this.historyChat = res.history || [];
    //     this.response = res.result_data;
    //   } else {
    //     this.response = res.result_img
    //   }
    // },
    urlSateBase64Encode (base64Str) {
      if (!base64Str) return;
      let safeStr = base64Str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
      // const UriSafe = (src: string) => src.replace(/[+\/]/g, (m0) => m0 == '+' ? '-' : '_').replace(/=+$/m, ‘');
      return safeStr;
    },
    streamHttp (apiTestUrl, requestData) {
      this.abortController = new AbortController();
      console.log(apiTestUrl, requestData)
      const headers = new Headers({});
      const body = JSON.stringify(
        requestData
      );
      const index = this.chatRecords.length;
      this.loading = true;
      this.showStopBtn = true;
      let tempData = ''
      const eventSource = fetchEventSource(`/apitest${apiTestUrl}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        signal: this.abortController.signal,
        onmessage: (e) => {
          if ((e.data.startsWith('[(') || e.data.startsWith('[[') || e.data.startsWith("[{'role'") || e.data.startsWith('[{"role"')) && e.data.length > 15) {
            let temp = e.data
            const jsonCompatibleString = temp.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');
            try {
              const jsonArray = JSON.parse(jsonCompatibleString);
              this.historyChat = jsonArray;
            } catch (error) {
              console.log(error, "解析失败.....")
            }
            this.loading = false;
            this.showStopBtn = false;
            this.abortController.abort();
            eventSource.close();
          } else {
            let message = e.data;
            message = message.replaceAll("<|im_end|>", "").replace(/\\n/g, '\n')
              .replace(/\\r/g, '\r')
              .replace(/\\t/g, '\t')
              .replace(/\\f/g, '\f')
              .replace(/\\v/g, '\v')
              .replace(/\\\\/g, '\\')
            tempData += message;
            this.chatRecords.splice(index, 1, {
              type: 2,
              word: tempData,
            });
            const chatContent = this.$refs.srcollRef;
            if (chatContent) {
              chatContent.scrollTop = 9999999999;
            }
          }
        },
        onclose: () => {
          console.log("close");
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
        onerror: (error) => {
          console.log("error", error);
          this.$message.error('服务器响应失败')
          let index = this.chatRecords.length;
          this.chatRecords.splice(index, 1);
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
      });
    },
    stopAnswers () {
      this.abortController.abort();
      this.loading = false;
      this.showStopBtn = false;
    }
  },


}
</script>

<style lang="scss" scoped>
::v-deep.chartWrap {
  width: 1024px;
  height: 628px;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
  .multi_modal_img {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-upload {
      height: 180px;
      .el-upload-dragger {
        height: 180px;
      }
    }
    .temp_img {
      max-width: 250px;
      max-height: 170px;
      margin-bottom: 10px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .line {
    width: 600px;
    border: 1px solid rgb(230, 227, 227);
    margin: 5px auto;
  }
  .chat_content {
    scroll-behavior: smooth;
    height: calc(100% - 100px) !important;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 24px;
    padding-bottom: 10px !important;
  }
  .isCogvlmModel {
    height: calc(100% - 320px) !important;
  }
  .chat_content::-webkit-scrollbar {
    width: 8px; /* 滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  .chat_content::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* 滚动条滑块的颜色 */
    border-radius: 4px; /* 滚动条滑块的圆角 */
  }
  .chat_content::-webkit-scrollbar-track {
    background-color: #e1e1e1; /* 滚动条滑道的颜色 */
    border-radius: 4px; /* 滚动条滑道的圆角 */
  }
  .chatRecords {
    > div {
      margin: 10px 0px;
    }
    .ai_msg {
      background: #f5f5f5;
      border-radius: 2px;
      text-align: left;
      width: 100%;
      .avatar_wrap {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
        }
        span {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          margin-left: 8px;
        }
      }
      .word {
        padding: 0px 15px !important;
        box-sizing: border-box;
        max-width: 100%;
      }
      .el-image {
        width: 80px;
        margin-left: 40px;
      }
    }
    .person_msg {
      .word {
        box-sizing: border-box;
        max-width: 928px;
        padding: 9px 9px;
        background: #2774f7;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        display: inline-block;
        text-align: left !important;
      }
    }
  }
  .chart-input {
    bottom: 16px;
    position: absolute;
    width: 992px;
    height: 52px;
    background: #ffffff;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    .el-input__inner {
      width: 992px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px;
      border: none;
      box-sizing: border-box;
      padding-right: 70px;
    }

    .chart-btn {
      width: 48px;
      height: 36px;
      border-radius: 2px;
      background: #2774f7;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 8px;
      right: 4px;
      .el-icon-top,.el-icon-video-pause {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .stopIcon {
    position: absolute;
    bottom: 27px;
    right: 100px;
    font-size: 30px !important;
  }
}

.loading {
  position: absolute;
  width: 1px;
  height: 1px;
  left: 35px;
  bottom: 100px;
}

.loading:before,
.loading:after {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 15px;
  content: "";
  border-radius: 100%;
  background-color: #000;
}

.loading:before {
  left: -15px;
  animation: ball-pulse infinite 0.75s -0.4s cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.loading:after {
  right: -15px;
  animation: ball-pulse infinite 0.75s cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

@keyframes ball-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.result-streaming:after {
  -webkit-animation: blink-scale 1s steps(5, start) infinite;
  animation: blink-scale 1s steps(5, start) infinite;
  content: "▋";
  display: inline-block;
  margin-left: 10px;
  vertical-align: baseline;
}
@keyframes blink-scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
</style>
import { render, staticRenderFns } from "./VoiceToText.vue?vue&type=template&id=850a2692&scoped=true&"
import script from "./VoiceToText.vue?vue&type=script&lang=js&"
export * from "./VoiceToText.vue?vue&type=script&lang=js&"
import style0 from "./VoiceToText.vue?vue&type=style&index=0&id=850a2692&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "850a2692",
  null
  
)

export default component.exports